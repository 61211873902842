
import {
  defineComponent,
  toRefs,
  ref,
  computed,
  onMounted,
  onBeforeUnmount
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ProfileCard from "../components/ProfileCard.vue";
import Popup from "../components/Popup.vue";
import OwnProfileCard from "../components/OwnProfileCard.vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { User } from "../constants/types";
import {
  DELETE_USER_CTF_MUTATION,
  ADD_USER_CTF_MUTATION,
  FIND_BY_CTF_QUERY,
  FIND_CFT_QUERY,
  USERS_IN_CTF_QUERY
} from "../constants/gql/ctf";
import { SEND_MESSAGE_MUTATION } from "../constants/gql/message";
import { FIND_TAGS } from "@/constants/gql/tag";

export default defineComponent({
  props: {
    id: { type: String, required: true }
  },
  components: {
    ProfileCard,
    OwnProfileCard,
    Popup
  },
  setup(props) {
    const { id } = toRefs(props);
    const availableProfiles = ref<User[]>([]);
    const isActiveDropdown = ref("");
    const sendMessageTo = ref<string | null>(null);
    const isSendMessageOpen = ref(false);
    const textToSend = ref<string | null>(null);
    const windowWidth = computed(() => window.innerWidth);
    const router = useRouter();
    const store = useStore();
    const ownUsername = ref(store.state.user.username);
    const user = computed(() => store.state.user);
    const currentStep = computed(() => store.state.step);
    const isRegistered = ref<boolean>(user.value.ctfs.includes(id.value));
    const { result: data } = useQuery(FIND_CFT_QUERY, { ctfId: id.value });
    const { mutate: sendMessageMutation } = useMutation(SEND_MESSAGE_MUTATION);
    const { mutate: ctfRegistrationMutation } = useMutation(
      ADD_USER_CTF_MUTATION
    );

    const { loading: tagLoading, result: tagResult } = useQuery(FIND_TAGS);
    const { result, refetch: refetchCtfLength } = useQuery(USERS_IN_CTF_QUERY, {
      id: id.value
    });
    const { onResult, refetch, fetchMore } = useQuery(
      FIND_BY_CTF_QUERY,
      {
        id: id.value,
        offset: 0,
        limit: 18
      },
      { fetchPolicy: "no-cache", nextFetchPolicy: "network-only" }
    );
    const { mutate: ctfDeleteUserMutation } = useMutation(
      DELETE_USER_CTF_MUTATION
    );

    onResult(({ data }) => {
      availableProfiles.value = data.findByCtf;
    });

    const loadMoreUsers = async () => {
      fetchMore({
        variables: {
          offset: availableProfiles.value.length
        }
      });
    };

    const onScroll = () => {
      const scrollIsOver: boolean =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      if (scrollIsOver && isRegistered.value) {
        loadMoreUsers();
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", onScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", onScroll);
    });

    const toggleDropDown = () => {
      if (isActiveDropdown.value == "") {
        isActiveDropdown.value = "is-active";
      } else {
        isActiveDropdown.value = "";
      }
    };

    const register = async () => {
      const {
        data: { addUserCtf }
      } = await ctfRegistrationMutation({ ctf: id.value });
      isRegistered.value = addUserCtf.ctfs.includes(id.value);
      await refetch();
    };

    const unregister = async () => {
      const {
        data: { deleteUserCtf }
      } = await ctfDeleteUserMutation({ ctf: id.value });
      isRegistered.value = deleteUserCtf.ctfs.includes(id.value);
      refetchCtfLength();
    };

    const onSendMessage = (username: string) => {
      sendMessageTo.value = username;
      isSendMessageOpen.value = true;
    };

    const sendMessage = async () => {
      if (
        sendMessageTo.value == null ||
        textToSend.value == null ||
        textToSend.value.trim() == ""
      ) {
        return;
      }

      const sentMessage = await sendMessageMutation({
        input: {
          to: sendMessageTo.value,
          text: textToSend.value
        }
      });
      store.commit("addMessage", sentMessage.data.newMessage);
      isSendMessageOpen.value = false;
      router.push("/inbox/" + sendMessageTo.value);
    };

    const prevAction = () => {
      if (currentStep.value == 1) {
        router.push("/");
      } else if (currentStep.value == 2) {
        unregister();
      }
      store.commit("updateStep", currentStep.value - 1);
    };

    const nextAction = () => {
      if (currentStep.value == 1) {
        register();
      } else if (currentStep.value == 2) {
        const element = document.getElementById("scrollTo-0");
        if (element && windowWidth.value <= 768) {
          element.scrollIntoView();
        }
      }
      store.commit("updateStep", currentStep.value + 1);
    };

    return {
      register,
      unregister,
      data,
      availableProfiles,
      isRegistered,
      toggleDropDown,
      isActiveDropdown,
      onSendMessage,
      isSendMessageOpen,
      sendMessage,
      sendMessageTo,
      textToSend,
      loadMoreUsers,
      user,
      windowWidth,
      ownUsername,
      currentStep,
      prevAction,
      nextAction,
      result,
      tagLoading,
      tagResult
    };
  }
});
