
import { getLink } from "@/utils/utils";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Tag } from "@/constants/types";
import { TAG_COLORS } from "@/constants/tagColor";

export default defineComponent({
  props: {
    id: String,
    level: String,
    tagLoading: Boolean,
    description: String,
    username: { type: String, required: true },
    hashedUsername: { type: String, required: true },
    language: { type: Array as PropType<string[]>, required: true },
    skills: { type: Array as PropType<string[]>, required: true },
    tag: { type: Array as PropType<Tag[]>, required: true }
  },
  setup(props) {
    const store = useStore();
    const ownUsername = ref(store.state.user.username);
    const router = useRouter();

    const computedLevel = computed(() => {
      switch (props.level) {
        case "I know nothing about CTFs":
          return "Beginner";
        case "I have played CTFs sometimes":
          return "Novice";
        case "I regularly play CTFs":
          return "Intermediate";
        case "I am a Hacking Expert / Professional":
          return "Expert";
      }
    });

    const colorLevel = computed(() => {
      switch (props.level) {
        case "I know nothing about CTFs":
          return "info";
        case "I have played CTFs sometimes":
          return "success";
        case "I regularly play CTFs":
          return "warning";
        case "I am a Hacking Expert / Professional":
          return "danger";
      }
    });

    const findTagColor = (tagName: string) => {
      const res = props.tag.find((tag: Tag) => tag.name === tagName);

      if (res) {
        return res.color;
      } else {
        return TAG_COLORS[Math.floor(Math.random() * 5)];
      }
    };

    const imageUrl = ref("");

    onMounted(async () => {
      imageUrl.value = await getLink(props.hashedUsername);
    });

    const route = (url: string) => {
      router.push(url);
    };

    return {
      imageUrl,
      computedLevel,
      colorLevel,
      ownUsername,
      route,
      findTagColor
    };
  }
});
